import React from 'react';

const CreateAccountLink = ({ userType }) => {
  if (userType !== "Driver") {
    return (
      <div><br /><br /><a href="/createuser">Create New User</a></div>
    );
  }
};

export default CreateAccountLink;



import React from 'react';


const BackgroundWithOverlay = ({ backgroundImage, overlayColor, children }) => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    width: '100%',
    height: '100vh', // Adjust this based on your needs
  };

  const overlayStyle = {
    backgroundColor: overlayColor,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.5, // Adjust the transparency
    zIndex: 1,
  };

  const contentStyle = {
    position: 'relative',
    zIndex: 2,
    color: 'white', // Default text color, adjust as needed
    textAlign: 'center',
    padding: '20px',
  };

  return (
    <div style={backgroundStyle}>
      <div style={overlayStyle}></div>
      <div style={contentStyle}>{children}</div>
    </div>
  );
};

export default BackgroundWithOverlay;
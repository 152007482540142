//Account creation page
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const LoginPage = () => {
    const [message, setMessage] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [phonenumber, setPhoneNumber] = useState('');
    const [formData, setFormData] = useState({
        id: -1,
        email: '',
        password: '',
        username: '',
        firstname: '',
        lastname: '',
        birthdate: '',
        phonenumber: ''
    });
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    //api call to check if user is logged in, navigate home if so
    useEffect(() => {
        fetch('/api/checkadmin')
            .then((response) => response.json())
            .then((result) => {
                if (result.message === 'false') {
                    navigate('/');
                } 
            });
    }, []);

    useEffect(() => {
        fetch('/api/getuserinfoadmin?id=' + id)
            .then((response) => response.json())
            .then((result) => {
                setUsername(result.username);
                setEmail(result.email);
                setPassword(result.password);
                setFirstname(result.firstname);
                setLastname(result.lastname);
                setBirthdate(result.birthdate);
                setPhoneNumber(result.phonenumber);
            });
    }, []);


    const updateForm = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    //when form is submitted ('create' hit)
    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.id === -1) formData.id = id;
        if (formData.username === "") formData.username = username;
        if (formData.email === "") formData.email = email;
        if (formData.password === "") formData.password = password;
        if (formData.birthdate === "") formData.birthdate = birthdate;
        if (formData.firstname === "") formData.firstname = firstname;
        if (formData.lastname === "") formData.lastname = lastname;
        if (formData.phonenumber === "") formData.phonenumber = phonenumber;
        console.log(formData);

        //send post request to server with user info
        fetch('/api/updateuser', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        })
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                //if succesfull login go to home page
                let message2 = result.message;
                if (message2 === 'success') {
                    navigate('/home');
                } else {
                    setMessage("Account Update Failed");
                }
            })
            .catch((error) => {
                console.error('Error with POST request:', error);
            });
    };

    //html for login form and password/account creation options
    return (
        <div>
            <h1>Edit User Info</h1>
            <p style={{ color: 'red' }}>{message}</p>
            <form id="editaccountform" onSubmit={handleSubmit}>
                <p>Username: <input type="text" id="username" name="username" defaultValue={username} onChange={updateForm} /></p>
                <p>Email: <input type="text" id="email" name="email" defaultValue={email} onChange={updateForm} /></p>
                <p>Password: <input type="text" id="password" name="password" defaultValue={password} onChange={updateForm} /></p>
                <p>First Name: <input type="text" id="firstname" name="firstname" defaultValue={firstname} onChange={updateForm} /></p>
                <p>Last Name: <input type="text" id="lastname" name="lastname" defaultValue={lastname} onChange={updateForm} /></p>
                <p>Date Of Birth: <input type="text" id="birthdate" name="birthdate" defaultValue={birthdate} onChange={updateForm} /></p>
                <p>Phone Number: <input type="text" id="phonenumber" name="phonenumber" defaultValue={phonenumber} onChange={updateForm} /></p>
                <input type="submit" value="Submit" />
            </form>
            <a href="/home">Back</a>
        </div>
    );
};

export default LoginPage;
//Account creation page
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import BasicTable from '../components/BasicTable';
import AdminEditAccountsLink from '../components/AdminAccountEditLink';

const EditUsers = () => {
    const [message, setMessage] = useState('');
    const [cols, setCols] = useState([]);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    //api call to check if user is logged in, navigate home if so
    useEffect(() => {
        fetch('/api/checkadmin')
            .then((response) => response.json())
            .then((result) => {
                if (result.message === 'false') {
                    navigate('/');
                } 
            });
    }, []);

    useEffect(() => {
        fetch('/api/getusers')
            .then((response) => response.json())
            .then((result) => {
                setCols(result.cols);
                setData(result.data);
            });
    }, []);
    

    //html for login form and password/account creation options
    return (
        <div>
            <h1>Edit User Accounts</h1>
            <p style={{ color: 'red' }}>{message}</p>
            <BasicTable data={data} columns={cols} />
            <a href="/home">Back</a>
        </div>
    );
};

export default EditUsers;
import React from 'react';
import '../styles/Header.css'; // Import the CSS for styling

const Header = () => {
  return (
    <header className="header">
      <h1 className="logo">FLEET</h1>
      <div className="header-icons">
        <button className="icon-button">🛒</button>
        <button className="icon-button">☰</button>
      </div>
    </header>
  );
};

export default Header;
import React from 'react';

const BasicTable = ({ columns, data }) => {
    if (columns) {} else {columns = []};
    if (data) {} else {data = []};
    return (
        <table border="1">
            <thead>
                <tr>
                    {columns.map((col) => (
                        <th key={col}>{col}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((item, idx) => (
                    <tr key={idx}>
                        {Object.entries(item).map(([key, val], idx) => (
                            <td key={idx}>
                                {key === "href" ? (
                                    <a href={val.route}>{val.link}</a>
                                ) : (
                                    val
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default BasicTable;



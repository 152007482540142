import React from 'react';
import '../styles/AboutContent.css'; // Import the CSS for styling

const contentData = {
  team: `
    <h2>Team Number: TeamNumber_</h2>
    <p>Sprint: Sprint_</p>
    <p>Release Date: ReleaseDate_</p>
    <p>Product Name: ProductName_</p>
    <p>Product Description: ProductDescription_</p>
  `,
  overview: `
    <h1>Welcome to FLEET – Drive Smart, Earn Rewards</h1>
    <p>At FLEET, we believe great driving deserves recognition. 
    That’s why we’ve created a unique rewards program that lets you redeem points 
    for maintaining safe and responsible driving behavior. The more you drive smart, 
    the more points you earn!</p>
    
    <h2>How It Works:</h2>
    <ol>
      <li><strong>Earn Points:</strong> Drive safely and follow best practices on the road. Each good behavior gets you closer to your next reward.</li>
      <li><strong>Explore Rewards:</strong> Browse through exclusive products and services offered by our trusted sponsors, all available to you through your earned points.</li>
      <li><strong>Redeem and Enjoy:</strong> Use your points to redeem items that make your life on and off the road even better!</li>
    </ol>
    
    <p>Why join? Drive with purpose, explore exclusive products, and boost your efficiency. Start driving smarter today, and turn your road time into reward time!</p>
  `,
  history: 'This is the history of FLEET.',
  mission: 'This is the mission statement of FLEET.',
  leadership: 'This is information about FLEET’s leadership.',
};

const AboutContent = ({ selectedSection, TeamNumber, Sprint, ReleaseDate, ProductName, ProductDescription }) => {
  const content = contentData[selectedSection]
    .replace("TeamNumber_", TeamNumber)
    .replace("Sprint_", Sprint)
    .replace("ReleaseDate_", ReleaseDate)
    .replace("ProductName_", ProductName)
    .replace("ProductDescription_", ProductDescription);

  return (
    <div className="about-content">
      <h3>{selectedSection.charAt(0).toUpperCase() + selectedSection.slice(1)}</h3>
      {selectedSection === 'overview' || selectedSection === 'team' ? (
        <div dangerouslySetInnerHTML={{ __html: content }} />
      ) : (
        <p>{content}</p>
      )}
    </div>
  );
};

export default AboutContent;

import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import HeroImage from './components/HeroImage';
import AboutContent from './components/AboutContent';
import AboutSidebar from './components/AboutSidebar';
import './App.css';
/*import AboutSection from './AboutSection';

import Footer from './Footer';*/


const AboutPage = () => {
  const [selectedSection, setSelectedSection] = useState('team');
  const [teamNumber, setTeamNumber] = useState('');
  const [sprint, setSprint] = useState('');
  const [releaseDate, setReleaseDate] = useState('');
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');

  useEffect(() => {
      fetch('/api/about')
          .then((response) => response.json())
          .then((result) => {
              setTeamNumber(result.teamnumber);
              setSprint(result.sprint);
              setReleaseDate(result.releasedate);
              setProductName(result.productname);
              setProductDescription(result.productdescription);
          });
  }, []);

  return (
    <div className="AboutPage">
      <Header />
      <HeroImage />
      <div className="content-wrapper">
        <AboutContent selectedSection={selectedSection} TeamNumber={teamNumber} Sprint={sprint} ReleaseDate={releaseDate} ProductName={productName} ProductDescription={productDescription} />
        <AboutSidebar onSelect={setSelectedSection} />
      </div>
    </div>
  );
};

export default AboutPage;
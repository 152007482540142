//Origin page, login options
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const [message, setMessage] = useState('');
    const formData = {
        email: '',
        password: ''
    };
    const navigate = useNavigate();

    //functions to update username and password vals
    const updateEmail = (e) => {
        formData.email = e.target.value;
    }
    const updatePassword = (e) => {
        formData.password = e.target.value;
    }

    //when form is submitted ('login' hit)
    const handleSubmit = (e) => {
        e.preventDefault();

        //send post request to server with user/pass
        fetch('/api/updatepassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        })
            .then((response) => response.json())
            .then((result) => {
                //if succesfull update go to login page
                let message2 = result.message;
                if (message2 === 'success') {
                    navigate('/', {state: { message: 'Password Reset Succesfully'}});
                } else {
                    setMessage(message2);
                }
            })
            .catch((error) => {
                console.error('Error with POST request:', error);
            });
    };

    //html for login form and password/account creation options
    return (
        <div>
            <h1>Password Reset</h1>
            <p style={{ color: 'red' }}>{message}</p>
            <form id="login" onSubmit={handleSubmit}>
                <label id="emailLabel" htmlFor="email">Email: </label><input type="text" id="email" name="email" onChange={updateEmail} />
                <br /><label htmlFor="password" id="passwordlabel">Password: </label><input type="text" id="password" name="password" onChange={updatePassword} />
                <br /><input id="resetpasswordsubmit" type="submit" value="Reset Password" />
            </form>
            <a href="/" >Back</a>
        </div>
    );
};

export default LoginPage;
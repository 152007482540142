//Account creation page
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AccountDropdown from '../components/AccountDropdown';

const CreateUser = () => {
    const [message, setMessage] = useState('');
    const [loginUserType, setLoginUserType] = useState('');
    const [newUserType, setNewUserType] = useState('');
    const formData = {
        username: '',
        password: '',
        email: '',
        firstname: '',
        lastname: '',
        birthdate: '',
        phonenumber: '',
        usertype: 'Driver',
        active: 0
    };
    const navigate = useNavigate();

    //api call to check if user is logged in, navigate to login if so
    useEffect(() => {
        fetch('/api/checklogin')
            .then((response) => response.json())
            .then((result) => {
                if (result.message === 'false') {
                    navigate('/');
                } else if (result.usertype === "Driver") {
                    navigate('/home');
                } else {
                    setLoginUserType(result.usertype);
                }
            });
    }, []);

    //functions to update form values
    const updatePassword = (e) => {
        formData.password = e.target.value;
    }
    const updateEmail = (e) => {
        formData.email = e.target.value;
    }
    const updateUserType = (e) => {
        formData.usertype = e;
        setNewUserType(e);
    }
    //when form is submitted ('create' hit)
    const handleSubmit = (e) => {
        e.preventDefault();
        formData.usertype = newUserType;

        //send post request to server with user info
        fetch('/api/createaccount', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        })
            .then((response) => response.json())
            .then((result) => {
                //if succesfull login go to home page
                let message2 = result.message;
                if (message2 === 'success') {
                    navigate('/home', { state: { message: 'User Created!' } });
                } else {
                    setMessage(result.message);
                }
            })
            .catch((error) => {
                console.error('Error with POST request:', error);
            });
    };

    //html for login form and password/account creation options
    return (
        <div>
            <h1>Create New User</h1>
            <p style={{ color: 'red' }}>{message}</p>
            <form id="createaccountform" onSubmit={handleSubmit}>
                <AccountDropdown userTypeSelectChange={updateUserType} userType={loginUserType} />
                <p>Email: <input type="text" id="email" name="email" onChange={updateEmail} /></p>
                <p>Default Password: <input type="text" id="password" name="password" onChange={updatePassword} /></p>
                <input type="submit" value="Create" />
            </form>
            <a href="/home">Back</a>
        </div>
    );
};

export default CreateUser;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginForm = ({ setMessage }) => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const navigate = useNavigate();

  // Functions to update username and password values
  const updateForm = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // send post request to server with user/pass
    fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData)
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Response received:', result); //for testing
        // If successful login, go to home page
        if (result.message === 'success') {
          navigate('/home');
        } else {
          setMessage('Login Failed.');
        }
      })
      .catch((error) => {
        console.error('Error with POST request:', error);
        setMessage('An error occurred.');
      });
  };

  return (
    <div className="login-container">
        <form id="login" onSubmit={handleSubmit}>
            <label id="usernamelabel" htmlFor="username">Username/Email:</label>
            <input type="text" id="username" name="username" value={formData.username} onChange={updateForm} />
            <br />
            <label htmlFor="password" id="passwordlabel">Password:</label>
            <input type="password" id="password" name="password" value={formData.password} onChange={updateForm} />
            <br />
            <input id="loginsubmit" type="submit" value="Log In" />
        </form>
        
    </div>
  );
};

export default LoginForm;



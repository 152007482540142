//Account creation page
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const LoginPage = () => {
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const formData = {
        username: '',
        password: '',
        firstname: '',
        lastname: '',
        birthdate: '',
        phonenumber: ''
    };
    const navigate = useNavigate();
    const location = useLocation();
    

    //api call to check if user is logged in, navigate home if so
    useEffect(() => {
        fetch('/api/checklogin')
            .then((response) => response.json())
            .then((result) => {
                if (result.message === 'true') {
                    navigate('/home');
                } 
                if (location.state) {
                    setEmail(location.state.email);
                } else {
                    navigate('/');
                }
            });
    }, []);

    //functions to update form values
    const updateUsername = (e) => {
        formData.username = e.target.value;
    }
    const updatePassword = (e) => {
        formData.password = e.target.value;
    }
    const updateFirstname = (e) => {
        formData.firstname = e.target.value;
    }
    const updateLastname = (e) => {
        formData.lastname = e.target.value;
    }
    const updateBirthdate = (e) => {
        formData.birthdate = e.target.value;
    }
    const updatePhonenumber = (e) => {
        formData.phonenumber = e.target.value;
    }

    //when form is submitted ('create' hit)
    const handleSubmit = (e) => {
        e.preventDefault();

        formData.usertype = location.state.usertype;

        //send post request to server with user info
        fetch('/api/activateaccount', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        })
            .then((response) => response.json())
            .then((result) => {
                //if succesfull login go to home page
                let message2 = result.message;
                if (message2 === 'success') {
                    navigate('/', {state: {message: 'Account Activated!'}});
                } else {
                    setMessage(result.message);
                }
            })
            .catch((error) => {
                console.error('Error with POST request:', error);
            });
    };

    //html for login form and password/account creation options
    return (
        <div>
            <h1>Create Account</h1>
            <p style={{color: 'red'}}>{message}</p>
            <form id="activateaccountform" onSubmit={handleSubmit}>
                <p>Email: {email}</p>
                <p>Username: <input type="text" id="username" name="username" onChange={updateUsername} /></p>
                <p>Password: <input type="text" id="password" name="password" onChange={updatePassword} /></p>
                <p>First Name: <input type="text" id="firstname" name="firstname" onChange={updateFirstname} /></p>
                <p>Last Name: <input type="text" id="lastname" name="lastname" onChange={updateLastname} /></p>
                <p>Date Of Birth: <input type="text" id="birthdate" name="birthdate" onChange={updateBirthdate} /></p>
                <p>Phone Number: <input type="text" id="phonenumber" name="phonenumber" onChange={updatePhonenumber} /></p>
                <input type="submit" value="Create" />
            </form>
        </div>
    );
};

export default LoginPage;
import React from 'react';

const AdminEditAccountsLink = ({ userType }) => {
  if (userType === "Admin") {
    return (
      <div><a href="/editusers">Edit Users</a></div>
    );
  }
  return;
};

export default AdminEditAccountsLink;



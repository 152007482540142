import React from 'react';
import '../styles/AboutSidebar.css'; // Import the CSS for styling

const AboutSidebar = ({ onSelect }) => {
  const sections = ['Team', 'Overview', 'History', 'Mission', 'Leadership'];

  return (
    <aside className="sidebar">
      <ul>
        {sections.map((section) => (
          <li key={section} onClick={() => onSelect(section.toLowerCase())}>
            {section}
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default AboutSidebar;
//home page, no functionality/UI currently
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateAccountLink from './components/CreateAccountsLink';
import AdminAccountEditLink from './components/AdminAccountEditLink';

const HomePage = () => {
    const [message, setMessage] = useState('');
    const [userName, setUserName] = useState('');
    const [userType, setUserType] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetch('/api/checklogin')
            .then((response) => response.json())
            .then((result) => {
                //setMessage not working for some reason, just used result.message
                //makes sure user is logged in
                if (result.message !== 'true') {
                    navigate('/', { state: { message: 'Must Be Logged In' } });
                } else {
                    setUserName(result.username);
                    setUserType(result.usertype);
                }
            });
    }, []);

    const logout = (e) => {
        e.preventDefault();
        //send request to remove session
        fetch('/api/logout')
            .then((response) => response.json())
            .then((result) => {
                //if succesfull logout go to login page
                let message2 = result.message;
                if (message2 === 'success') {
                    navigate('/');
                } else {
                    setMessage("LogOut Failed.");
                }
            })
            .catch((error) => {
                console.error('Error with logout request:', error);
            });
    };

    const deleteaccount = (e) => {
        e.preventDefault();
        //send request to remove session
        fetch('/api/deleteaccount')
            .then((response) => response.json())
            .then((result) => {
                //if succesfull logout go to login page
                let message2 = result.message;
                if (message2 === 'success') {
                    navigate('/');
                } else {
                    setMessage("Delete Failed.");
                }
            })
            .catch((error) => {
                console.error('Error with logout request:', error);
            });
    };
    
    return (
        <div>
            <h1>Home Page</h1>
            <p>{message}</p>
            <p>Hello, {userName}!</p>
            <button id="logoutbutton" onClick={logout}>LogOut</button><br />
            <button id="deletebutton" onClick={deleteaccount}>Delete Account</button><br />
            <a href="/resetpassword">Change Password</a><br />
            <CreateAccountLink userType={userType} />
            <AdminAccountEditLink userType={userType} />
            <a href="/editaccount">Edit Personal Info</a>
        </div>
    );
};

export default HomePage;
//Origin page, login options

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stylesheet.css';  // Styles specific to the login page
import BackgroundWithOverlay from '../components/Background';
import LoginForm from '../components/LoginForm';
import backgroundImage from '../images/fleet.webp';

const LoginPage = () => {
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  // API call for checking login status (currently does nothing)
  useEffect(() => {
    fetch('/api/checklogin')
      .then((response) => response.json())
      .then((result) => {
        if (result.message === 'true') {
          navigate('/home');
        }
      });
  }, [navigate]);

  return (
    <BackgroundWithOverlay backgroundImage={backgroundImage} overlayColor="#004CE1">
      <div className="login-page">
        <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr' }}>
          <div className="loginPage-col-left">
            <h4>FLEET</h4>
          </div>
          <div className="loginPage-col-right">
            <h1>Sign-in</h1>
            <p style={{ color: 'red' }}>{message}</p>
            <LoginForm setMessage={setMessage} />
            <p>New User?</p><a href="../createaccount">Create Account</a><br />
            <button id="resetpassword">Reset Password</button>
            <a href="../about">About Page</a>
          </div>
        </div>
      </div>
    </BackgroundWithOverlay>
  );
};

export default LoginPage;
import React, {useState} from 'react';

const AccountDropdown = ({ userTypeSelectChange, userType }) => {
  const [newUserType, setNewUserType] = useState('');

  const updateNewUserType = (e) => {
    setNewUserType(e.target.value);
    userTypeSelectChange(e.target.value);
  }

  if (userType === "Driver") {
    return ;
  }
  if (userType === "Sponsor") {
    return (
      <div style={{display: 'inline'}}>
        <p style={{display: 'inline'}}>User Type: </p>
        <select style={{display: 'inline'}} id="usertypeselect" onChange={updateNewUserType} >
          <option value="Driver">Driver</option>
          <option value="Sponsor">Sponsor</option>
        </select>
      </div>
    );
  }
  return (
    <div style={{display: 'inline'}}>
      <p style={{display: 'inline'}}>User Type: </p>
      <select style={{display: 'inline'}} id="usertypeselect" onChange={updateNewUserType} >
        <option value="Driver">Driver</option>
        <option value="Sponsor">Sponsor</option>
        <option value="Admin">Admin</option>
      </select>
    </div>
  );
};

export default AccountDropdown;



import React, { useEffect, useState } from 'react';

const SecondPage = () => {
    const [message, setMessage] = useState('');
  
    useEffect(() => {
      fetch('/api/test')
        .then((response) => response.json())
        .then((data) => setMessage(data.message));
    }, []);
    return (
      <div>
        <h1>{message}</h1>
      </div>
    );
};

export default SecondPage;
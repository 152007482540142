import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//import each element from its JS file
import HomePage from './HomePage'; 
import SecondPage from './SecondPage';
import LoginPage from './Login/LoginPage';
import CreateAccount from './Login/CreateAccount';
import CreateUser from './Login/CreateUser';
import ActivateAccount from './Login/ActivateAccount';
import ResetPassword from './Login/ResetPassword';
import EditAccount from './Login/EditAccount';
import EditUsers from './Login/EditUsers';
import EditUser from './Login/EditUser';
import AboutPage from "./AboutPage";

//List of routes and the elements they go to
function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/second" element={<SecondPage />} />
        <Route path="/createaccount" element={<CreateAccount />} />
        <Route path="/createuser" element={<CreateUser />} />
        <Route path="/activateaccount" element={<ActivateAccount />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/editaccount" element={<EditAccount />} />
        <Route path="/editusers" element={<EditUsers />} />
        <Route path="/edituser" element={<EditUser />} />
        <Route path="/about" element={<AboutPage />} />
      </Routes>
    </Router>
  );
}

export default App;


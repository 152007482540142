import React from 'react';
import '../styles/HeroImage.css'; // Import the CSS for styling
import truckImage from '../images/about-hero.png'; 

const HeroImage = () => {
  return (
    <div className="hero-image">
      <img src={truckImage} alt="Fleet of trucks" />
    </div>
  );
};

export default HeroImage;